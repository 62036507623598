var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c("GeneralTable", {
        ref: "GeneralTable",
        attrs: {
          "module-name": _vm.moduleName,
          "table-name": _vm.tableName,
          "row-key": "c_id",
          url: _vm.url,
          operation: _vm.operation,
          "can-search": _vm.canSearch,
          "can-import": _vm.canImport,
          "can-add": _vm.canAdd,
          "can-edit": _vm.canEdit,
          "can-delete": _vm.canDelete,
          "can-download": _vm.canDownload,
          "can-batch-edit": _vm.canBatchEdit,
          "cascader-check-strictly": false,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import waves from '@/directive/waves'; // waves directive
import GeneralTable from '@/components/GeneralTable';
import { log } from 'util';
import checkPermission from '@/utils/permission'; // 权限判断函数
import { generalQuery } from '@/api/general';
import { auitPrint } from '@/api/shopaudit';
import { PAGE_URL } from '@/utils/UrlUtils';
export default {
  name: 'Index',
  directives: {
    waves: waves
  },
  components: {
    GeneralTable: GeneralTable
  },
  data: function data() {
    return {
      tableName: 'v_client_audit_process_instance',
      moduleName: 'shop_list_module',
      viewType: parseInt(this.$route.query.viewType != null ? this.$route.query.viewType : 1),
      url: {
        getUrl: '/shop-audit/audit',
        method: 'post',
        query: {
          param: {
            viewType: this.$route.query.viewType
          }
        }
      },
      canSearch: true,
      canAdd: false,
      canEdit: false,
      canDelete: false,
      canBatchEdit: true,
      canDownload: false,
      canImport: false,
      operation: [],
      loading: false,
      dialogVisible: false,
      hasShopAuthorizationPrivilege: checkPermission('master.shop.authorization.audit'),
      hasPrintAuthorizationPrivilege: checkPermission('master.shop.authorization.print')
    };
  },
  created: function created() {
    var _this = this;
    if (this.$store.getters.hasShopAuditPrivilege || this.viewType == 3) {
      this.canDownload = true;
    }
    //console.log(this.viewType)
    if (this.viewType == 2 || this.viewType == 3) {
      this.operation = [{
        name: this.$t("label.view"),
        method: this.handleView,
        type: 'primary'
      }];
      this.canBatchEdit = false;
    } else {
      this.operation = [{
        name: this.$t("label.audit"),
        method: this.handleAudit,
        type: 'primary',
        hide: function hide(item) {
          //console.log(item)
          return item.b_audit_status != 2;
          // return (item.b_has_similar_shop==null || item.b_has_similar_shop != 1)
        }
      }];
      this.url.query.param['b_audit_status'] = [1];
      // this.setTableColumns()
    }
  },
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    // 保存离开本页面时的查询条件，当再次进入本页面时回显
    if (this.$refs.GeneralTable) {
      sessionStorage.setItem(window.location.href + '_LIST_QUERY', JSON.stringify(this.$refs.GeneralTable.getListQuery()));
    }
    next();
  },
  methods: {
    handleSinglePrint: function handleSinglePrint(index, item) {
      this.handlePrint(item.c_id);
    },
    handlePrint: function handlePrint(ids) {
      var _this2 = this;
      auitPrint(ids).then(function (res) {
        if (!res.data.success) {
          _this2.$message({
            message: res.data.message,
            type: 'error'
          });
          return;
        }
        sessionStorage.setItem('printInfoList', JSON.stringify(res.data.data));
        window.open(PAGE_URL + 'print.html');
      });
    },
    handleAudit: function handleAudit(index, item) {
      //console.log(item)
      var path = '/master/shop/audit/view/' + item.c_id + '?viewType=' + this.viewType + '&updatePkId=' + item.b_pk_id + '&bShopChannel=2';
      this.$router.push({
        path: path
      });
    },
    handleView: function handleView(index, item) {
      //console.log(item)
      var path = '/master/shop/audit/view/' + item.c_id + '?viewType=' + this.viewType + '&updatePkId=' + item.b_pk_id + '&bShopChannel=2';
      this.$router.push({
        path: path
      });
    },
    setTableColumns: function setTableColumns() {
      // const tdds = this.$store.getters.tableDataDictionary.tables
      // if(tdds) {
      //   const tempColumns = JSON.parse(JSON.stringify(tdds.find(value => value.name === this.similarTableName).tableColumns))
      //   this.similarTableColumns = tempColumns.filter(n=>n.code!='c_client_id')
      // }
      //console.log(this.similarTableColumns)
    },
    printAuthorization: function printAuthorization(index, item) {
      this.loading = true;
      var listQuery = {
        param: {
          c_id: item.c_id
        }
      };
      // return new Promise((resolve, reject) => {
      //   generalQuery('/shop-audit/audit/similar-shop', listQuery).then(response => {
      //     // console.log(response)
      //     if (response.data.code !== '0') {
      //       this.$notify({
      //         title: this.$t('notification.error.title'),
      //         message: response.data.message,
      //         type: 'error',
      //         duration: 3000
      //       })
      //     }
      //     this.similarShopListData = response.data.data
      //     const currentShop = {
      //       b_shop_id: this.$t('label.newShop'),
      //       b_shop_code:item.b_shop_code,
      //       b_shop_name:item.b_shop_name,
      //       b_shop_address:item.b_shop_address,
      //       b_city_name:item.b_city_id,
      //       b_distributor_name:item.b_distributor_id,
      //       b_shop_status:item.b_shop_status
      //     }
      //     this.similarShopListData.unshift(currentShop)
      //     this.dialogVisible=true
      //     this.loading = false
      //     resolve(response)
      //   }).catch(error => {
      //     this.loading = false
      //     reject(error)
      //   })
      // })
    },
    closeDialog: function closeDialog() {
      this.dialogVisible = false;
    },
    tableRowClassName: function tableRowClassName(_ref) {
      var row = _ref.row,
        rowIndex = _ref.rowIndex;
      if (rowIndex === 0) {
        return 'warning-row';
      }
      return '';
    }
  }
};